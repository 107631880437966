// .modalConteiner{
//     width: 100vw;
//     height: 100vh;
//     position: fixed;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 10000;
//     top: 0;
//     overflow: hidden;
//     background-color: rgba(000, 000, 000, .9);
    
// }



.modalDiv{
color: #fafafa;
background-color: #172027;
padding: 2rem;
    .textConteiner{
        margin-top: 1rem;
        padding: 2rem;
        width: 75%;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center
    };

    .daysConteiner{
        margin-top: 1rem;
        padding: 2rem;
        width: 20rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        gap: .55rem;
        flex-wrap: wrap;
        &-label{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .1rem
        }
    };
    &__figcaption{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        padding: .7rem;
        &-img{
            
            width: 1rem
        }
    };
    &__text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: .9rem;
        /* or 110% */
        color: #fafafa;
        text-align: center;
        letter-spacing: 0.01em;
        height: 8rem;
        width: 25rem;
        min-width: 25rem;
        max-width: 25rem;
        min-height: 8rem;
        max-height: 8rem;
        border-radius: 5px;
        background-color: #172027;
        color: white;
        outline: #334756;
        border: none;
    }
    &__update{
        height: 8rem;
        width: 25rem;
        min-width: 25rem;
        max-width: 25rem;
        min-height: 8rem;
        max-height: 8rem;
        border-radius: 5px;
        background-color: #172027;
        border: #fafafa;
        outline: #fafafa 1px solid;
        color: white;
        &:focus{
            outline: #fafafa solid 2px
        }

    }
    &__button{
        width:15.63rem;
        height: 4.70rem ;

        background:linear-gradient(to right, #334756 0%, rgba(188, 188, 188, 0.74)  51%, #334756  100%);
   
border: 0.732572px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(21.6109px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 36.6286px;
        background-size: 200% auto;
        color: white;
        border: 0px;
        border-radius: 17.31rem;
        height: 3rem;
        font-size: 1.2rem;
        transition: 1s ;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1rem;
        &:hover {
            background-position: right center; /* change the direction of the change here */
            color: white;
            text-decoration: none;
          }
    }
   .success{
        background: green;
    }
}