

.verFacturasConteiner {
  width: 95%;
  height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  position: relative;
  gap: 1rem;
  margin-top: 3rem;
  padding-bottom: 6rem;
  &__listaFacturas {
    width: 100%;
    &-table {
      border-collapse: collapse;
      width: 100%;
      color: #172027;
      border: 1.84534px black solid;
      font-size: 0.7rem;
      position: relative;
      &-thead {
        height: 3rem;
        background: #172027;

        /* Linea contenedor */
        position: sticky;
        top: 0;
        border: 1.84534px solid rgba(188, 188, 188, 0.74);
        box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(54.4375px);
        font-weight: 600;
        line-height: 37px;
        text-align: center;
        cursor: default;
        z-index: 20;


        &-row {
          &-th {
            font-size: 0.8rem;
            width: 100px;
          }
        }
      }
      &-body {
        color: #fafafa;
        &-row {
          height: 3rem;
          border: 1.84534px solid rgba(188, 188, 188, 0.74);
          box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
          backdrop-filter: blur(15px);
          background: #607284;
          &:hover {
            background: #e2e2e285;
          }
          img{
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}


.inputDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: .5rem;
}

.selected{
  background-color: #172027;
}
.inputDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: fit-content;
  padding: .5rem;
}

.selected {
  background-color: #172027;
}

.inputDiv-label {
  display: flex;
  gap: 1rem;
  color: #fafafa;
  input,
  select {
    width: 100px;
    height: 44px;
    background: transparent;
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    color: #fafafa;
    outline: none;
   
    

    &::placeholder{
      color: #fafafa;
      padding: 3px;
    }
  }
  option {
    background:#172027;
border: 1px solid black;}

}
.rowGris{
  width: 2016px;
height: 50px;


background: linear-gradient(90deg, #FFB60B 0%, #EA5A24 100%);

/* Linea contenedor */

border: 1.84534px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(54.4375px);
}

.mantine-1avyp1d{
  width: inherit;

}
.loaderRefresh{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleStatus{
  display: flex;
  align-items: center;
    justify-content: center;
  &-pago{
    background-color: green;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;

  }
  &-impago{
    background-color: red;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
  
  }
  &-autorizado{
    background-color: yellow;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
  
  }
}
.smallB {
  width:100px
}

.buttonCheck{
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
