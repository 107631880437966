.management {
    width: 90%;
    overflow-y: hidden;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;


    .managementDiv{

        width:100%;
        height: 100%;
        border: white solid 1px;
        border-radius: 36px;
        display: flex;
        justify-content:space-between;
        overflow: hidden;
    }
    &__divEmails {
        height: 37rem;
        width:50%;
        overflow-y: hidden;

        position: relative;
        background-color: #607284;
        color: #fafafa;
        &-header {
            overflow-y: auto;
            background: linear-gradient(180deg, #BABABA 0%, rgba(186, 186, 186, 0) 100%);
            filter: drop-shadow(4px 4px 25px rgba(0, 0, 0, 0.2));
            text-align: center;
            p{
                font-size: 1.5rem;
            }
            label{
                
                display: flex;
                gap: 1rem;
                font-size: 1.2rem;
                justify-content: center;
                align-items: baseline;
                margin-bottom: 5px;

            }
        }

        &-body {
            height: 30rem;

            overflow-y: auto;
            &-list {
                overflow-y: auto;
                
                &-item{
                    background: #607284;
                    /* Linea contenedor */
                    height: 3rem;
                    border: 1.84534px solid rgba(188, 188, 188, 0.74);
                    box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
                    backdrop-filter: blur(15px);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: white;
                    &:hover{
                        
                        background: #172027;
                    }
                }
            }
            .active{
                background: #172027;
            }
        }
    }
    &__divData{
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #607284;
        padding: 1rem;
        label{
            width: 100%;
        }
        p{
            font-size: 1rem;
            color: #172027;
            font-weight:800;
            span{
                color: #FFB60B;
                font-weight:800;
            }
        }
    }
}

.divMails{
    display: flex;
    flex-direction: column;
    height: 15rem;
    input{
        width: 100%;
    }
    ol{
        list-style: none;
        overflow: auto;
        border-radius: 5px;
        button{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            border: solid 2px #fafafa;
            background: transparent;
            font-size: 1rem;
            color: #fafafa;
            &:hover{
                color: red;
                border: solid 2px red;
            }
        }
    }
}


.divButtonsTools{
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    div{
        display: flex;
        align-items:center;
        justify-content:baseline;
        gap: 1rem;
        width: 50%;
        border-bottom: 1px dashed #FFB60B;
    }
    button{
        width: fit-content;
        height: 1rem;
        width: 1rem;
        border-radius: 5px;
        border: solid 1px black;
    }
    .si{
        background: green;
        color: white;
    }
    .no{
        background:red;
        color: white;
    }

}