.modalConteiner {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: rgba(000, 000, 000, .3);
}
.daysConteinerNoE{
    width: fit-content;
    height: 6rem;
}

.modalDiv {
    background: rgba(255, 255, 255, 0.2);
border: 1.84534px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(54.4375px);
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    position: relative;
    overflow: hidden;



    .textConteiner {
        margin-top: 1rem;
        padding: 2rem;
        width: 75%;
        border-radius: 1rem;
    }

    ;

    &__figcaption {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 50%;
        width: 8rem;
        height: 8rem;
        padding: .7rem;

        &-img {

            width: 4rem
        }
    }

    ;

    &__text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: .9rem;
        /* or 110% */
        color: #fafafa;
        text-align: center;
        letter-spacing: 0.01em;
    }

    &__button {
        width: 15.63rem;
        height: 4.70rem;

        background: linear-gradient(to right, #334756 0%, rgba(188, 188, 188, 0.74) 51%, #334756 100%);

        border: 0.732572px solid rgba(188, 188, 188, 0.74);
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(21.6109px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 36.6286px;
        background-size: 200% auto;
        color: white;
        border: 0px;
        border-radius: 17.31rem;
        height: 3rem;
        font-size: 1.2rem;
        transition: 1s;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1rem;

        &:hover {
            background-position: right center;
            /* change the direction of the change here */
            color: white;
            text-decoration: none;
        }
    }

}