.ManagementCards {
    width: 22%;
    height: fit-content;
    border-radius: 10px;
    position: relative;
    padding: 5px;


    background: #172027;
    box-shadow: #172027 10px 10px 20px;
    cursor: pointer;

    a {
        display: flex;
        align-items: center;

        gap: .5rem;
    }

    &__text {
        color: #fafafa;
        display: flex;
        flex-direction: column;
        gap: .3rem;
        font-size: .8rem;
    }

    &__avatar {
        img {
            border-radius: 50%;
            width: 6rem;
            height: 6rem;
            border: solid 2px #FFB60B;
        }
    }

    &:hover {
        // box-shadow: #FFB60B 2px 2px 10px;
        transform: scale(1.02);
    }
    transition: 0.15s ease-in ;

}

.ManagementCards__avatar {

    position: relative;
    margin: 0 auto;

    /*To Add effect on the background*/
    background: linear-gradient(to left,
            DarkBlue, DodgerBlue);
    border-radius: 10px;
    width: px;
    height: 40px;
    box-sizing: border-box;
    transition: all 1s;
    border: 0.4vh solid transparent;
    border-top-color: dodgerblue;
    border-bottom-color: dodgerblue;
}

/*To create pseudo-class */
.ManagementCards__avatar:before,
.ManagementCards__avatar:after {
    content: "";
    border: inherit;
    position: absolute;
    top: -0.5vh;
    left: -0.5vh;

    /*To Add effect on the background*/
    background: linear-gradient(to left,
            DarkBlue, DodgerBlue);
    border-radius: inherit;
    height: 100%;
    width: 100%;
}


.hexagon {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    overflow: hidden;
    background-position: center;
    background-size: contain;
    height: 6rem;
    width: 5rem;
}