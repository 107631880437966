$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

#navBar{
    position: absolute;
    left: .5rem;
    top: .5rem;
    height: 100vh;
    z-index: 100;
}
.pro-sidebar{
    border-radius: 8px;
    height: fit-content;
    overflow: hidden;
    background-color: #141C22

    
   
}

.pro-icon-wrapper{
    margin-right: 15px !important
}

.pro-sidebar-inner{
    border-radius: 8px;
    background-color: transparent !important;
    
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    padding:  5px 15px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
    height: auto !important;
    position: fixed;
    min-width: 220px;
    max-width: 270px;
    background-color: #1d1d1d;
    max-height: 100%;
    padding-left: 3px;
    margin-left: 55px;
    border-radius: 8px;
    margin-top: -3rem;
    overflow: hidden;
    div{
        background: #2b2b2b;
    }

}

.pro-menu-item .pro-sub-menu:hover{
    .pro-inner-list-item .popper-element {
        visibility: visible;
    }
}

.pro-icon{
    img{
        width : 23,57px;
height: 20px
    }
}


.pro-inner-item{
    padding: 0 !important;
    margin: .2rem 1rem;
}
.pro-menu-item{
    padding: 0 !important;
    margin: 1rem 0;
}
.pro-icon-wrapper{
    background: transparent !important;
}

.pro-menu-item .pro-sub-menu{
    margin: .5rem 0;
}

.pro-sidebar-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .navBar__header-logoCol{
        height: 2.5rem;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    }
    



  
    .navBar__header-logo{
        height: 4.5rem;
    }
}