*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
	text-decoration: none;
	border: none;
	outline: none;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgba(188, 188, 188, 0.74);
}

*::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  /* color of the scroll thumb */
  
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #fafafa;
  /* creates padding around scroll thumb */
}
#contenido{

display: flex;
flex-direction: column;
align-items:center ;
height: 100vh;
width: 100%;
position: relative;
top: 2rem;
left: 2rem;
justify-content: center;
}
#userNav{

}
#navBar{
  position: absolute;
  top:3rem;
  width: fit-content;
}
#cuerpo{
  position: absolute;
  background-image: url('./Assets/Img/fondo.svg');
  background-position: center;
  background-size:cover;
  width: 100%;
 top: 0;
}
body{

}
td{
  text-align: center;
}


td:hover{
  background: #334756;
}



.nohover:hover{

  background-color: transparent;
}


.buttonStandard{
  padding: 5px;
  width: 150px;
height: 40px;
position: relative;
color: #fafafa;
background: linear-gradient(90deg, #FFB60B 0%, #EA5A24 100%);


display: flex;
align-items: center;
justify-content: center;
font-size: .8rem;
border: 0.732572px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(21.6109px);
/* Note: backdrop-filter has minimal browser support */
cursor: pointer;
border-radius: 36.6286px;

}

.buttonStandard{
  padding: 5px;
  width: 150px;
height: 40px;
position: relative;
color: #fafafa;
background: #FFB60B;

align-items: center;
justify-content: center;
font-size: .8rem;
border: 0.732572px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(21.6109px);
/* Note: backdrop-filter has minimal browser support */
cursor: pointer;
border-radius: 36.6286px;

}

.buttonIcon{
  padding: 5px;
  width: 3rem;
  height: 3rem;
position: relative;
color: #fafafa;
background: #FFB60B;


border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: .8rem;
border: 0.732572px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(21.6109px);
/* Note: backdrop-filter has minimal browser support */
cursor: pointer;
border-radius: 36.6286px;

}
