.subirFacturasMain{
    width: 30%;
    height: 80%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    overflow: auto;
    background: #172027;
  margin-bottom: 1rem;
border: 1.84534px solid #FFB60B;
box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(54.4375px);
/* Note: backdrop-filter has minimal browser support */
p{
  color: #fafafa;
}
.divProv{
  display: flex;
  flex-wrap: wrap;
  color: #fafafa;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  width: 70%;
}
border-radius: 33px;
    gap: 2rem;
    &__display{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        &-conteiner{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &-text{
                font-size:2rem;
                color: black;
            }
            &-img{
                width: 5rem;
            }
            &:hover{
                cursor: pointer;
                .facturasMain__display-conteiner-text{
                    color: #ea5a24;
                    ;
                    
                }
               .facturasMain__display-conteiner-img{
                    filter: drop-shadow(0 0 0.75rem #ea5a24);
                }
            }
        }
    }
}
.subirFacturasMainA{
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

/* Note: backdrop-filter has minimal browser support */
p{
color: #fafafa;
}
.divProv{
display: flex;
flex-wrap: wrap;
color: #fafafa;
justify-content: center;
align-items: center;
gap: .5rem;
width: 70%;
}
border-radius: 33px;
  gap: 2rem;
  &__display{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10rem;
      &-conteiner{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &-text{
              font-size:2rem;
              color: black;
          }
          &-img{
              width: 5rem;
          }
          &:hover{
              cursor: pointer;
              .facturasMain__display-conteiner-text{
                  color: #ea5a24;
                  ;
                  
              }
             .facturasMain__display-conteiner-img{
                  filter: drop-shadow(0 0 0.75rem #ea5a24);
              }
          }
      }
  }
}

.links{
    text-decoration: none;
}

.uploadButtons{background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%)}



.file-area {
    text-align: center;
    position: relative;
    width: 100%;
    font-size: .8rem;
    background-color: transparent;
    padding: 1rem;
    overflow-y: auto;
    input[type=file] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;

    }
    
    .file-dummy {
      width: 100%;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content:center ;
      flex-direction: column;
      gap: 2rem;
      border-radius: 10px;
      text-align: center;
      transition: background 0.3s ease-in-out;
      color: white;
      p{
        font-size: 1.5rem;
      }
      .success {
        display: none;

        
      }
    }
    
    &:hover .file-dummy {
      background-color: transparent;
    }
    

    
    input[type=file]:valid + .file-dummy {
      background-color: transparent;
      overflow: hidden;
      color: white;
      height: fit-content;
      padding: 10px;
      border: black 1px solid;
      .success {
        display: inline-block;
      }
      .default {
        display: none;
      }
    }
  }
  
  
  .dataUploaded{
      color: #fafafa;
      border-bottom: black 1px solid;
      padding: 5px;
      display: flex;
      justify-content:flex-start;
      align-items:center;
      align-self: center;
      gap: 2rem;
      overflow: auto;
      img{
        width: 1.3rem;
        height: 1.3rem;
      }
      &:nth-child(odd){
        background-color: #172027;
    }
  }
  
  .dataUploadedDiv{
      border: solid 2px black;
      border-radius: 10px;
      height: 70%;
      overflow-y: auto;

  }

.abrirArchivos{
  background: black;
  width: 6rem;
  height: 6rem;
 border: #FFB60B solid 1px;
 border-radius: 50%;
  padding: .4rem;
  display: flex;
  align-items: center;
  justify-content:center ;
  flex-direction: column;
  img{
    width: 2rem;
    height: 2rem;
  }
}