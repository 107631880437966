$toggle-indicator-size: 24px; // changing this number will resize the whole toggle
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.5;
$highContrastModeSupport: solid 2px transparent;

$light: #FFB60B;
$mid: #EA5A24;

$dark:
#172027;
$speed: .5s;
/* 

The following vars come from my theme. 
You'll need to replace with your own color values. 


*/
$track-border: $mid;
$track-background: $light;
$focus-ring: 0px 0px 0px 2px $dark;

// Toggle specific styles
.toggle {
	align-items: center;
	border-radius: 100px;
	display: flex;
	font-weight: 700;
	margin-bottom: 16px;
justify-content: center;
	&:last-of-type {
		margin: 0;
	}
}

// Since we can't style the checkbox directly, we "hide" it so we can draw the toggle.
.toggle__input {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	// This style sets the focus ring. The ":not([disabled])" prevents the focus ring from creating a flash when the element is clicked.
	&:not([disabled]):active + .toggle-track,
	&:not([disabled]):focus + .toggle-track {
		border: 1px solid transparent;
		box-shadow: $focus-ring;
	}

	&:disabled + .toggle-track {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.toggle-track {
	background: $track-background;
	border: 1px solid $track-border;
	border-radius: 100px;
	cursor: pointer;
	display: flex;
	height: $track-height;
	margin-right: 12px;
	position: relative;
	width: $track-width;
}

.toggle-indicator {
	align-items: center;
	background: $dark;
	border-radius: $toggle-indicator-size;
	bottom: 2px;
	display: flex;
	height: $toggle-indicator-size;
	justify-content: center;
	left: 2px;
	outline: $highContrastModeSupport;
	position: absolute;
	transition: $speed;
	width: $toggle-indicator-size;
}

// The check mark is optional
.checkMark {
	fill: #fff;
	height: $toggle-indicator-size - 4;
	width: $toggle-indicator-size - 4;
	opacity: 0;
	transition: opacity $speed ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
	background: $dark;
	transform: translateX($track-width - $track-height);

	.checkMark {
		opacity: 1;
		transition: opacity $speed ease-in-out;
	}
}

@media screen and (-ms-high-contrast: active) {
	.toggle-track {
		border-radius: 0;
	}
}


.CBConteiner {
  width: 90%;
  height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden  ;
  margin-top: 3rem;
  &__logo {
    position: absolute;
    top: 50%;
    right: 45%;
    filter: drop-shadow(0 0 0.1rem black);
    opacity: 0.5;
    z-index: 0;
  }
  &__table {
    border-collapse: collapse;
    width: 100%;
    color: #172027;

    font-size: 0.7rem;
    position: relative;
    &-thead {
      background: #172027;
      color: #fafafa;
      height: 3rem;
      /* Linea contenedor */
      position: sticky;
      top: 0;
      box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(54.4375px);
      font-weight: 600;
      line-height: 37px;
      text-align: center;
      cursor: default;
      z-index: 20;

      &-row {
        &-th {
          width: 33%;
          font-size: 0.8rem;
        }
      }
    }
    &-body {
      color: #fafafa;
      &-row {
        height: 2rem;
        border: 1.84534px solid rgba(188, 188, 188, 0.74);
        box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(15px);
        background: #607284;
        a {
          color: white;
          text-decoration: none;
        }
        &:hover {
          background: #e2e2e285;
        }
      }
    }
  }
  &__tablePlacement {
    border-collapse: collapse;
    width: 100%;
    color: #172027;
    border: 1.84534px black solid;
    font-size: 0.7rem;
    position: relative;
    &-thead {
      height: 5.313rem;
      background: #172027;
      color: #fafafa;
      /* Linea contenedor */
      position: sticky;
      top: 0;
      border: 1.84534px solid rgba(188, 188, 188, 0.74);
      box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(54.4375px);
      font-weight: 600;
      line-height: 37px;
      text-align: center;
      cursor: default;
      z-index: 20;
border
      &-row {
        &-th {
          font-size: 0.8rem;
        }
      }
    }
    &-body {
      color: #fafafa;
      &-row {
        height: 3rem;
        border: 1.84534px solid rgba(188, 188, 188, 0.74);
        box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(15px);
        background: #607284;
        &:hover {
          background: #e2e2e285;
        }
      }
    }
  }

  &__tablePlacementLog {
    width: 50%;
    div{
      background-color:#172027;
      color: #fafafa;p{
        background-color:#172027;
        color: #fafafa
      }
    }
  }

  &__tablePlacementButtons {
    width: 15.63rem;
    height: 4.7rem;

    background: linear-gradient(
      to right,
      #334756 0%,
      rgba(188, 188, 188, 0.74) 51%,
      #334756 100%
    );
    border: 0.732572px solid rgba(188, 188, 188, 0.74);
    box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(21.6109px);
    border-radius: 36.6286px;
    background-size: 200% auto;
    color: white;
    border: 0px;
    border-radius: 17.31rem;
    height: 3rem;
    font-size: 1.2rem;
    transition: 1s;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    &:hover {
      background-position: right center; /* change the direction of the change here */
      color: white;
      text-decoration: none;
    }
  }
}

#submit-to-cm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  gap: .5rem;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}
th {
  button {
    background-color: red;
    border: 2px solid black;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
  }
}

#divLogs {
  display: flex;
  gap: 2rem;
  padding: 3rem;
}

.circle{
  width: 3rem;
  border-radius: 50%;
}
.CBConteiner-div{
  width: 100%;
}
.divButt{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}
.sitetag{
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  
}
.CBConteiner__tablePlacement-body-row-td{
  input[type=text], select{
    width: 140px;
    height: 2.5rem;
    background: transparent;
    border: 1.84534px solid #FFB60B;
    border-radius: 10px;
    padding: 3px;
    color: #fafafa;
  }

}
.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .buttonStandard{
    margin-top: .3rem;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-text-fill-color: #fafafa !important;
 transition: background-color 5000s ease-in-out 0s !important;
}

