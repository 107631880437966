

.conteiner{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
   
}

.hero, .contenido{
    height: 50%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
   
}
.contenido{
    padding: 1rem;
    width: 70%;
}
.hero img{
    width: 75%;
}

.hero{
    background: #3c02a2;
    padding: 1rem;
}
.contenido{
    background: #fafafa;
    height: fit-content;
    overflow: auto;
}
.button{
    display: none;
}

@media (min-width : 720px) {

    .hero img{
        width: 60%;
    }
    .hero{
        padding: 3rem
    }


}


@media (min-width : 1024px) {

    .modalConteiner{
        position: absolute;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items:center;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;
    }
    .divModal{
        width: 40%;
        height: fit-content;
        background-color: #fafafa;
        padding: 1rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .divButton{
        width: 100%;
        height: 90%;
    }
    .textarea{
        max-width: 100%;
        min-width: 100%;
        max-height: 50vh;
        min-height: 50vh;
        height: 90%;
        padding: 1rem;
        border-radius: 8px;
        border: solid 1px black;
        
    }
    .button{
        display: inline-block;
        height: 2rem;
        width: 120px;
        font-weight: bold;
        border-radius: 8px;
        padding: 5px;
        color: #fafafa;
        background: #3c02a2;
        border: solid 1px black;
        cursor: pointer;
    }
    .button:hover{
        box-shadow: 3px 3px 3px #3c02a2;
    }
    .conteiner{
        display: flex;
        flex-direction: row;
        font-size: 1.5rem;
    }
    .hero, .contenido{
        height: 100%;
        gap: 1rem;
    }

}

.divButtons{
    display: flex;
    gap: 1rem;
    justify-content: center;
}

#creativeCode{
    margin-top: 5rem;
}