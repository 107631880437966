.userNav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3rem;
    width: 100vw;
    // height:4rem;
    // background:linear-gradient(0deg, rgba(195, 34, 34, 0) 0%, rgba(0,0,0,1) 110%);
    height: 0;
    z-index:99;
    position: absolute;
    top: 0;
    &__logOut{
        margin-right: 4rem;
        &-button{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            border: none;
            background-color: #c4c4c4;
            display: flex;
            align-items: center;
            justify-content: center;
            &-img{
                width: 1.2rem;
            }
            &-icon-avatar-img{
                width: 1rem;
            }
        }
        &-button:hover{
            background-color: #ffb60b;
        }
    }
.avatar{
    margin-right: 2rem;
    position: absolute;
    top: .8rem;
    z-index: 10;
    img{
        padding-top: 1rem;
        width: 3rem;
        cursor: pointer;
        transition: 1s ease-in;
        &:hover{
            filter: drop-shadow(0px 0px 8px #FFB60B);
        }
    }
}
&__dropDown{
    background-color: white;
    border-radius: 10px;
    box-shadow: 15px 21px 33px -15px rgba(0,0,0,1);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    top: 2rem;
    right: 3.5rem;
    gap: 1rem;
    padding: 2rem;
    z-index: 0;
    &-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        &-initials{
 
            border-radius: 50%;
            width: 5rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
        }

    }
    &-email{
        font-size: 0.7rem;
    }
    &-name{
        font-size: 2rem;
    }
    &-user{
        font-size: 1.5rem;
    }
    &-config{
        width: 3rem;
    }
    &-divConfig{
        width: fit-content;
    }
}
}


.ticketText:hover{
    text-decoration: underline
}
