// .modalConteiner{
//     width: 100%;
//     height: 100vh;
//     position: fixed;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 10000;
//     top: 0;
//     left: 0;
//     overflow: hidden;
//     background-color: rgba(000, 000, 000, .6);
// }

.modalDiv{
    // width: 694px;
    // height: 415px;
    // left: 743px;
    // top: 285px;
    // position: relative;
    
    // background: rgba(255, 255, 255, 0.2);
    // border: 1.84534px solid rgba(188, 188, 188, 0.74);
    // box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
    // backdrop-filter: blur(54.4375px);
    // /* Note: backdrop-filter has minimal browser support */
    
    // border-radius: 33px;

    &__close{
        position: absolute;
        right: 1rem;
        top: 1rem;
        &:hover{
            -webkit-filter: drop-shadow(1px 1px 0 black)
            drop-shadow(-1px -1px 0 black);
    filter: drop-shadow(1px 1px 0 black) 
    drop-shadow(-1px -1px 0 black)
        }
    }
    .textConteiner{
        margin-top: 1rem;
        padding: 2rem;
        width: 75%;
        border-radius: 1rem;
    };
    &__figcaption{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 50%;
        padding: .7rem;

    };
    &__text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: .9rem;
        /* or 110% */
        color: #fafafa;
        text-align: center;
        letter-spacing: 0.01em;
        outline: white;
    }
    &__button{
        width:15.63rem;
        height: 4.70rem ;

        background:linear-gradient(to right, #334756 0%, rgba(188, 188, 188, 0.74)  51%, #334756  100%);
   
border: 0.732572px solid rgba(188, 188, 188, 0.74);
box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
backdrop-filter: blur(21.6109px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 36.6286px;
        background-size: 200% auto;
        color: white;
        border: 0px;
        border-radius: 17.31rem;
        height: 3rem;
        font-size: 1.2rem;
        transition: 1s ;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1rem;
        &:hover {
            background-position: right center; /* change the direction of the change here */
            color: white;
            text-decoration: none;
          }
    }

}