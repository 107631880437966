.subirFacturasManual {
  
    
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    overflow-y: auto;

    ::placeholder {
        color: #fafafa;
    }
    textarea {
        max-width:250px;
        min-width: 250px;
        max-height: 5rem;
        min-height: 5rem;
        background: transparent;
        height: 44px;
        color: #fafafa;
        border: none;
        border: 1.5px solid #FFB60B
        
;
padding: 10px;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
    }
    &__form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        color: #fafafa;
        width: 100%;

        &-fieldset {
            background: #172027;

            border: 1.84534px solid #FFB60B
;
            box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
            backdrop-filter: blur(54.4375px);
            /* Note: backdrop-filter has minimal browser support */
            color: #fafafa;
            border-radius: 33px;
            padding: .7rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            gap: .2rem;
            flex-direction: column;
            // div {
            //     display: flex;
            //     align-items: center;
            //     width: fit-content;
            //     justify-content: center;
            //     margin: .5rem;
            //     gap: 1rem;

            //     textarea {
            //         max-width: 10.5rem;
            //         min-width: 10.5rem;
            //         max-height: 5rem;
            //         min-height: 5rem;
            //         background: transparent;
            //         height: 44px;
            //         color: #fafafa;
            //         border: none;
            //         border: 1.5px solid #FFFFFF;
            //         box-sizing: border-box;
            //         border-radius: 6px;
            //         outline: none;
            //     }
            // }
        }
    }

    input,
    select {
        border: none;
        border: 1.5px solid #FFB60B
;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 130px;
        background: #172027;
        height: 44px;
        color: #fafafa;

        option {
            background-color: rgb(94, 107, 117);
        }
    }
}

.inputfile {
    opacity: 0;
    width: 160px;
    height: 50px;
    position: absolute;
    cursor: pointer;
}

.file-input {
    label {
        display: block;
        position: relative;
        width: fit-content;
     
height: 40px;
position: relative;
color: #fafafa;
background: linear-gradient(180deg, rgba(255, 181, 11, 1) 0%, rgba(233, 90, 36, 1) 100%);
font-size: .8rem;
        border: 0.732572px solid rgba(188, 188, 188, 0.74);
        padding: 5px;
      
        border-radius: 25px;
        
        font-weight: 400;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 200;
        cursor: pointer;
        transition: transform .2s ease-out;
        border-radius: 36.6286px;
        
    }
    input,label{
        cursor: pointer;
        padding: 5px;
    }

    input:hover+label,
    input:focus+label {
        transform: scale(1.02);
    }

    input:focus+label {
        outline: 1px solid #000;
        outline: -webkit-focus-ring-color auto 2px;
    }
}

.fit{
    width: fit-content;
}

.mantine-rtjf98{
    background: linear-gradient(180deg, rgba(255, 181, 11, 0.78) 0%, rgba(233, 90, 36, 0.507) 100%);
    border-radius: 5px;
    color:#fafafa;
    position: absolute;
    top: 5rem;
    right: 5rem;
}
.pago{
    color: rgb(24, 245, 24);
}
.impaga{
    color : red
}
    
