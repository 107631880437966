.loginPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    background-image: url(../../Assets/Img/fondo.png);
    background-size: cover;

    &__hero{
            background-color: black;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &-logo{
                width: 50%;
            }
            box-shadow:  0px 0px 50px 50px black;;
    }
    &__login{
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: none;
            gap: 2rem;
            &-welcome{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-logo{
                    width: 25%;
                }
                &-text{
                    font-size: 1.75rem;
                }
            }
            &-divForm{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &-button{
                    width:15.63rem;
                    height: 4.70rem ;
            
                    background:linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
                    background-size: 200% auto;
                    color: white;
                    border: 0px;
                    border-radius: 17.31rem;
                    height: 3rem;
                    font-size: 1.2rem;
                    transition: 1s ;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        background-position: right center; /* change the direction of the change here */
                        color: white;
                        text-decoration: none;
                      }
                }
              
            }}}
    

@media only screen and (max-width:780px) {
    .loginPage{
        &__hero{
            display:none}
        
        &__login{
            background-color: black;
            width: 100vw;
            &-welcome{
                width: 50%;
                &-logo{
                    width: 30%;
                }}
            &-divForm{
                width: 80%;
            }
        }}
}