.home{
    z-index: 1;
    height:100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    img{

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

    }
    &__welcome{
        font-size: 3.5rem;
        color: #FFB60B;
        font-weight: 600;
        border-radius: 10px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}
.widget {
    &__container {
        width: 400px;
        height: fit-content;
        position: absolute;
        bottom: 15%;
        right: 10%;
    }
    &__row {
        display: flex;
        align-items: center;
        
        &_incurrent {   
            background-color: #8f6910;
            height: 30px;
        }
        &_current {
            height: 50px;
            background-color: #FFB60B;
        }
        &__link {
            cursor: pointer;
        }
        &__description {
            width: 50px;
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
        }
        &__date-description {
            // width: 50%;
            &_container {
                margin-left: auto;
                width: 40%;

            }
            &_wrapper {
                float: right;
                margin-right: 5px;
            }

            &_inner {
                position: relative;
                display: inline-block;
                font-size: medium;
                bottom: 0px;
            }
            &_text {
                margin-left: 4px;
                font-size: large;
                display: inline-block;
            }

        }
        &__summary {
            &_container {
                // margin-left: auto;
            }
            &_inner {
                margin-left: 10px;
            }
            &_current {
                font-size: large;
            }

            // width: 50%;
        }
    }
}