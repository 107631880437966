.Profile{
    width: 90%;
    height:80%;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    &__datosGenerales{
        height: 30%;
        width: 100%;
        background: #172027;
        display: flex;
        border-radius: 10px;
        border:  solid 1px black;
        align-items: center;
        padding: 1rem;
        gap: 5%;
        color: #fafafa;
        display: flex;
        &-img{
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                border-radius: 50%;
                height: 100%;
                border: solid 2px #FFB60B;
            }   
        }
        &-text{
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            p{
                font-size: .9rem;
               width: 48%;
               span{
                   color: #FFB60B;
               }
            }
        }

    }
    &__datosSensibles{
    height: 70%;
    width: 100%;
   
    &-buttons{
        display: flex;
        gap: 1rem;
        height: fit-content;
        
        height: fit-content;
        &-but{
            background-color: #FFB60B;
            width: 15%;
            color: Black;
            height: 2rem;
            position: relative;
            display: flex;
            align-items: center;
            padding: 5px;
            z-index:3 ;
            cursor: pointer;
            transition: .5s ease-in;
            border-top-left-radius:3px;
            border-top-right-radius:3px;
            overflow: hidden;
            &::after{
                z-index: 2;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-top: 3px solid #FFB60B;
                background-color: transparent;
                width: 100%;
                height: 3px;
            }
            &:hover{
                background: #172027;
                color: #FFB60B;
                box-shadow: 0px -10px 0px #FFB60B;
                &::before{
                    z-index: 2;
                    content: inherit;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-top: 15px solid #fafafa;
                    border-right: 15px solid transparent;
                    background-color: #fafafa;
                    width: 0;
                }
                &::after{
                    z-index: 2;
                    content: inherit;
                    position: absolute;
                    background-color: red;
                    bottom: 0;
                    left: 0;
                    border-bottom: 15px solid #FFB60B;
                    border-left: 15px solid transparent;
                    width: 100%;
                    height: 1rem;
                    background-color: #FFB60B;
                }
            }&-active{
                padding-left: 5px;
                background: #172027;
                color: #FFB60B;
                width: 15%;
                transition: .5s ease-in;
                box-shadow: 0px -10px 0px #FFB60B;
                border-top-left-radius:3px;
            border-top-right-radius:3px;
            z-index: 20;
            }
            
        }
    }
    &-display{
        height: fit-content;
        
        position: relative;
        font-size: 1rem;
        width: 100%;
        height: fit-content;
        background-color: #172027;
        padding-left: 1rem;
        color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
border-radius: 1rem;
border-top-left-radius: 0px;
        
        span{
            color: #FFB60B;
        }
        &-dG{
            margin-top: 1rem;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        justify-content: flex-start;
   
            gap: 5%;
            
            p{
                width: 40%;
                height: fit-content;
                margin-bottom: 2rem;
                
            }
        }
        &-edit{
            position: absolute;
            top: 1rem;
            right: 1rem;
            svg{
                width: 3rem;
                cursor: pointer;
            }
        }
    }
    }
}