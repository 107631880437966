.userManagement {
    width: 90%;
    height:80%;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
&__buscador{
    height: 15%;
    width: 100%;
    background: #172027;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: solid 2px #FFB60B;

padding: 1rem;
label{
    color: #fafafa;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    input{
        font-size: 1.5rem;
        padding: 10px;
        color: #fafafa;
        background: none;
        border: solid 1px #FFB60B;
        height: 2.5rem;
        width: 20rem;
        border-radius: 5px; 
    }
}

}&__users{
    height: 85%;
    width: 100%;
    background: none;
    padding: .5rem;
    gap: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  

}

}