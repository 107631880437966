.cargaPagosModal{
    height:100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: absolute;
    top: -2rem;
    left: -2rem;
    z-index: 100;
    background: rgba(#000000, 0.5);
    &__display{

        
        &-content{
            width: 50%;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            background: #172027;
            position: relative;
            border-radius: 1rem;
            .exitButton{
                position: absolute;
                top: 1rem;
                right:1rem;
                z-index: 20;
            }
            &-title{
                position: relative;
                top: 1rem;
                left: 1rem;
                color: #fafafa;
                font-size: 1.2rem;
                
                span{
                    color: #FFB60B;

                }
          
            }
            &-emails{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                color: #fafafa;
                margin-top: 3rem;
                width: 50%;
                height: 50%;
                input{
                    width: 100%;
                }
                ol{
                    height: 4rem;
                    overflow-y: auto;
                    width: 100%;
                    border: #fafafa solid 1px;
                    border-radius: .25rem;
                    font-size: .8rem;
                    li{
                        display: flex;
                        align-items: center;
                        gap: .2rem;
                    }
                }
                .deletemails{
                    background-color: red;
                    border: none;
                    height: 1rem;
                    width: 1rem;
                    text-align: center;
                    border-radius: 50%;
                    vertical-align: middle
                }
            }
            &-buttons{
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 50%;
                gap: 1rem;
            }
            &-subir{
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }
    }
}