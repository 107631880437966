.reporting {
    width: 85%;
    height: 100vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position:relative;

    padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .pointer{
        cursor: pointer;
    }


    
    #refreshButton{
        background-color: white;
        font-size: .95rem;
        padding: 1px;
        border: 1px solid black;
        border-radius: 5px;
        &:hover{
            border: 3px solid black;
        }
    }
    

        &__table {

            border-collapse: collapse;
            width: 100%;
            color: #172027;
            border: 1.84534px black solid;
            font-size: .7rem;
            position: relative;
            &-thead {
                height: 5.313rem;
                background: linear-gradient(90deg, #FFB60B 0%, #EA5A24 100%);

                /* Linea contenedor */
                position: sticky;
                top: 0;
                border: 1.84534px solid rgba(188, 188, 188, 0.74);
                box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
                backdrop-filter: blur(54.4375px);
                font-weight: 600;
                line-height: 37px;
                text-align: center;
                cursor: default;
                z-index: 20;
        
                &-row{
                    height: 2rem;
                    &-th{
                        font-size: .8rem;
                        &-filtersReporting{
                            width: 100%;
                            text-align: right;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            gap: 3rem;
                            margin: 2rem;
                            position: sticky;
                            height: 0;
                            input{
                                border-radius: 5px;
                            };
                            select{
                                border-radius: 5px;
                            }
                            &-div{
                                width: 10rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 1rem;
                                height: 1rem;
                                &-buttom{
                                    color: black;
                                    font-size: 1rem;
                                    background: white;
                                    &:disabled,
                                    [disabled]{
                                      border: 1px solid #999999;
                                      background-color: #cccccc;
                                      color: #666666;
                                    }
                                }
                            }
                        }
                    }
                }

            }
            &-body {
                color: #fafafa;
                &-row {
                  height: 2.5rem;
                  border: 1.84534px solid rgba(188, 188, 188, 0.74);
                  box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
                  backdrop-filter: blur(15px);
                  background: #607284;
                  &:hover {
                    background: #e2e2e285;
                  }
                }
              }
        }
    }


.orange{
    background: #172027;

      input,
  select {
    width: 269px;
    height: 44px;
    background: transparent;
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    color: #fafafa;
    outline: none;
    

    &::placeholder{
      color: #fafafa;
      padding: 3px;
    }
  }
  option {
    background:#d3592c9a;
border: 1px solid black;}
}

