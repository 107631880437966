.ops__Conteiner {
    width: 90%;
    height: 100vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4rem;
    padding-top: 0;
    gap: 2rem;
    margin-top: 4rem;
    select {
        border: none;
        border: 1.5px solid #FFB60B
;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 130px;
        background: #172027;
        height: 44px;
        color: #fafafa;

        option {
            background-color: rgb(94, 107, 117);
        }
    }
    
    // &::-webkit-scrollbar {
    //     width: 1rem;
    //     /* width of the entire scrollbar */
    // }

    // &::-webkit-scrollbar-track {
    //     background: rgba(188, 188, 188, 0.74);
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: #334756;
    //     /* color of the scroll thumb */
    //     border-radius: 20px;
    //     /* roundness of the scroll thumb */
    //     border: 1px solid #334756;
    //     /* creates padding around scroll thumb */
    // }

    &-membrete {
        display: flex;
        background: #172027;

        border: 1.84534px solid #FFB60B;
        box-shadow: 0px 7.23636px 35.2773px rgba(0, 0, 0, 0.18);
        backdrop-filter: blur(54.4375px);
        /* Note: backdrop-filter has minimal browser support */
        color: #fafafa;
        border-radius: 10px;
        width: fit-content;

        &-dataConteiner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;

            &-logo {
                width: 10rem;
                filter: drop-shadow(0 0 0.01rem black)
            }

            &-text {
                text-align: left;
                font-size: .7rem;
                font-weight: 400;

                &-span {
                    color: red;
                }
            }
        }

        &-data {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding: 1rem;
            justify-content: center;
            align-items: center;

            &-row {
                display: flex;
                gap: 5rem;
                width: 100%;
                justify-content: flex-start;
                align-items: baseline;

                &-inputDiv {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    position: relative;
                    label{
                        font-size: .7rem;
                        position: absolute;
                        top: -.5rem;
                        left: .2rem;
                        background: #172027;

                    }
                    &-input {
                        border: none;
                        border: 1.5px solid #FFB60B;
                        box-sizing: border-box;
                        border-radius: 6px;
                        outline: none;
                        width: 148px;
                        background: transparent;
                        height: 44px;
                        color: #fafafa;

                        &::placeholder {
                            color: #fafafa;
                            font-size: .8rem;
                            padding: 4px;
                        }

                    }
                }
            }
        }

        &-inputDiv {
            display: flex;
        }
    }

    &-info {
        width: 100%;
        border: 1.84534px solid #FFB60B;
        &-table {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 2rem;
            background: #172027;
            padding: 1rem;
            border-radius: 1rem;
            border: 1.84534px solid #FFB60B;
            &-days{
                height: 100%;
            }
            .ops__Conteiner-info-table-row-data-inputDiv{
                display: flex;
                flex-direction: column;
                text-align: left;
                position: relative;
                label{
                    font-size: .7rem;
                    position: absolute;
                    top: -.5rem;
                    left: .2rem;
                    background: #172027;
                    color: #fafafa;
                }
            }
            input,button{
                color: #fafafa;
                width: 150px;
                border-radius: 10px;
                outline: none;
                border: 1.84534px solid #FFB60B;
                background: transparent;
                &::placeholder{
                    color: #fafafa;
                }
            }
            button{
                height: auto;
            }
            input{
                height: 2.5rem;
            }
   }
    }

    &-footer {
        display: flex;
        justify-content: center;
        
        text-align: left;
        flex-direction: column;
        height: fit-content;
        border: 1.84534px solid #FFB60B;
        color: #fafafa;
        align-items: center;
        background: #172027;
        border-radius: 1rem;
        width:100%;
        padding: 1rem;
        input {
            border: none;
            border: 1.5px solid #FFB60B;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 148px;
            background: transparent;
            height: 44px;
            color: #fafafa;

            &::placeholder {
                color: #fafafa;
                font-size: .8rem;
                padding: 4px;
            }

        }
        &-div {
            width: 30%;
            display: flex;
            align-content: center;
            justify-content: center;
            position: relative;
            p{
                font-size: 1rem;
            }
            flex-direction: column-reverse;

        
            &-row{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 1rem;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            display: flex;
            ;
            gap: 1rem;

            &-label {
                background-color: #d4d4d4;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;

            }

            &-textarea {
                width: 100%;
                min-width: 90%;
                max-width: 90%;
                min-height: 6rem;
                position: relative;
                outline: none;
                padding: .5rem;
                border: 1.84534px solid #FFB60B;
                background-color: transparent;
                box-sizing: border-box;
                border-radius: 6px;
                color: #fafafa;
                &::placeholder {
                    color: #fafafa;
                    font-size: .8rem;
                    padding: 4px;
                }
            }

            &-input {
                background-color: white;
                border: 1.84534px solid #FFB60B;
                background-color: transparent;
                box-sizing: border-box;
                border-radius: 6px;
                outline: none;
                color: #fafafa;
                position: relative;

                &::placeholder {
                    color: #fafafa;
                    font-size: .8rem;
                    padding: 4px;
                }

            }
        }
    }

    &-op {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        z-index: -1;
    }
}

.ops__Conteiner-info-table-row-data-input-img{
    width: 1rem;

    &:hover{
        filter: drop-shadow(0 1px 1px rgba(255, 255, 255, 1))
    }
}


.daysConteiner{
    color: #fafafa;
    padding: 0;
    p{
        width: fit-content;
    }
   
}
.divMails{
    ol{
        height: 3.5rem;
        border: 1.84534px solid #FFB60B;
        width: 100%;
        margin-left: 0;
    }
}

.ops__Conteiner-info-table-conteiner{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.ops__Conteiner-info-table-days{
    input,button{
        color: #fafafa;
        width: 150px;
        height: 2.5rem;
        border-radius: 10px;
        outline: none;
        border: 1.84534px solid #FFB60B;
        background: transparent;
        &::placeholder{
            color: #fafafa;
        }
    }            display: flex;
    flex-wrap: wrap;
    width: fit-content;
    gap: 2rem;
    background: #172027;
    padding: 1rem;
    border-radius: 1rem;
    border: 1.84534px solid #FFB60B;;
    display: flex;
    align-content: center;
    justify-items: center;
}

.divButtonsOp{
    display: flex;
    gap: 1rem;
}
.divinput{
    position: relative;
    height: fit-content;
    width: 100%;
    label{
        position: absolute;
        background: #172027;
        z-index: 3;
        top: -.5rem;
        left: .5rem;
        font-size: .7rem;
    }
}

.divDaysbutt{
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    p{
        width: 8rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 3rem;
    }
}

.dayMonthOp{
    display: flex;
    align-content: center;
    .monthConteiner{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        select {
            border: none;
            border: 1.5px solid #FFB60B
    ;
            box-sizing: border-box;
            border-radius: 6px;
            outline: none;
            width: 130px;
            background: #172027;
            height: 44px;
            color: #fafafa;
    
            option {
                background-color: rgb(94, 107, 117);
            }
        }
    }
}

.totalDiasOpsMes{
    width: fit-content;
}